﻿//Typography interim fix
$font-family: 'Montserrat';
$light: 300;
$normal: 400;
$semibold: 500;
$bold: 700;

// need to fix this for Angular 15 upgrades
// $display-4: mat-typography-level(64px, 112px, 300, 'Roboto', -0.0234em);
// $display-3: mat-typography-level(56px, 56px, 300, 'Roboto', -0.0089em);
// $display-2: mat-typography-level(45px, 48px, 300, 'Roboto', 0.0000em);
// $display-1: mat-typography-level(32px, 48px, 300, 'Roboto', 0.0000em);
// $headline: mat-typography-level(24px, 32px, 400, 'Roboto', 0.0000em);
// $title: mat-typography-level(20px, 32px, 500, 'Roboto', 0.0075em);
// $subheading-2: mat-typography-level(14px, 28px, 400, 'Roboto', 0.0094em);
// $subheading-1: mat-typography-level(16px, 24px, 500, 'Roboto', 0.0067em);
// $body-2: mat-typography-level(14px, 24px, 300, 'Roboto', 0.0179em);
// $body-1: mat-typography-level(16px, 20px, 400, 'Roboto', 0.0156em);
// $button: mat-typography-level(14px, 14px, 400, 'Roboto', 0.0893em);
// $caption: mat-typography-level(11px, 20px, 400, 'Roboto', 0.0333em);
// $input: mat-typography-level(inherit, 1.125, 300, 'Roboto', 1.5px);



// Foreground Elements

// Light Theme Text
$dark-text: #191c1d;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         $dark-disabled-text,
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              $dark-primary-text,
  slider-min:        $dark-primary-text,
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base:              $light-text,
  divider:           $light-dividers,
  dividers:          $light-dividers,
  disabled:          $light-disabled-text,
  disabled-button:   rgba($light-text, 0.3),
  disabled-text:     $light-disabled-text,
  elevation:         black,
  hint-text:         $light-disabled-text,
  secondary-text:    $light-accent-text,
  accent-text:       $light-accent-text,
  icon:              $light-text,
  icons:             $light-text,
  text:              $light-text,
  slider-min:        $light-text,
  slider-off:        rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #fbfcfe;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           $dark-focused,
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#fbfcfe, 0.04);
$light-bg-alpha-12:   rgba(#fbfcfe, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background:               $dark-background,
  status-bar:               $dark-bg-lighter-20,
  app-bar:                  $dark-bg-lighter-5,
  hover:                    $light-bg-alpha-4,
  card:                     $dark-bg-lighter-5,
  dialog:                   $dark-bg-lighter-5,
  tooltip:                  $dark-bg-lighter-20,
  disabled-button:          $light-bg-alpha-12,
  raised-button:            $dark-bg-lighter-5,
  focused-button:           $light-focused,
  selected-button:          $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle:   $dark-bg-lighter-10,
  unselected-chip:          $dark-bg-lighter-20,
  disabled-list-option:     $dark-bg-lighter-10,
);

// Compute font config
@include mat-core();

// Theme Config

//body {
//  font-family: $font-family;
//  font-weight: $light;
//  --primary-color: #d92828;
//  --primary-lighter-color: #cfe5ff;
//  --primary-darker-color: #001d34;
//  --text-primary-color: #{$light-primary-text};
//  --text-primary-lighter-color: #{$dark-primary-text};
//  --text-primary-darker-color: #{$light-primary-text};
//}
$mat-primary: (
  main: #d92828,
  lighter: #cfe5ff,
  darker: #001d34,
  200: #d92828, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);


body {
  --accent-color: #00658d;
  --accent-lighter-color: #c6e7ff;
  --accent-darker-color: #001e2d;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
  main: #00658d,
  lighter: #c6e7ff,
  darker: #001e2d,
  200: #00658d, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);


body {
  --warn-color: #a50012;
  --warn-lighter-color: #e4b3b8;
  --warn-darker-color: #8a000a;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #a50012,
  lighter: #e4b3b8,
  darker: #8a000a,
  200: #a50012, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat-palette($mat-warn, main, lighter, darker);



$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include angular-material-theme($theme);

.theme-alternate {
  @include angular-material-theme($altTheme);
}





// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 2rem
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'Roboto';
  }
}

//The following color usage is for app surfaces and tertiary color usage.

//Surface color usage Light
$surface-color: #DAE5E3;
$surface-lighter-color: #6F7978;
$surface-darker-color: #3F4948;
$text-on-surface-color: #{$surface-darker-color};
$text-on-surface-lighter-color: #{$surface-darker-color};
$text-surface-darker-color: #{$surface-darker-color};



// Default theme
$theme-map-light: (
  // Surface, container color and related varients * Will later add surface variations of lighter and darker

  // Body of application
  --app-body-color: $light-background, //Setting body of entire app to theme background // DO NOT CHANGE




  // Primary
  // Primary container
  --primary-container-color: #ffffff,
  --on-primary-container-color: #410003,

  // Secondary
  // Secondary container
  --secondary-container-color: #C6E7FF,
  --on-secondary-container-color: #410003,


  // Terciary
  --tertiary-color: #006497,
  --on-tertiary-color: #FFFFFF,
  --tertiary-container-color: #CCE5FF,
  --on-tertiary-container-color: #001D31,


  //Error container
  --error-container-color: #FFDAD6,
  --on-error-container-color: #410003,
  --error-color: #BA191F,



  //Surface
  --surface-color: #FBFCFE,
  --on-surface-color: #191C1D,
  --surface-variant-color: #DBE4E6,
  --on-surface-variant-color: #cfe5ff,
  --inverse-on-surface: #EFF1F2,


  //Surfaces light varients
  --surface-color-1: #EEF4F9,
  --surface-color-2: #E7F0F6,
  --surface-color-3: #DFEBF3,
  --surface-color-4: #DDEAF2,
  --surface-color-5: #D8E6F1,



  //Font opacity
  --light-opacity: rgba($dark-primary-text, 0.54),
  --disabled: rgba($dark-primary-text, 0.38),
);



//Dark theme (Not Applicable for this stage)
// Override the default light theme
$theme-map-dark: (
  --app-body-color: $dark-background, //Setting body of entire app to theme background // DO NOT CHANGE

  // Primary container dark theme
  --primary-container-color: #00504F,
  --on-primary-container-color: #6FF7F4,
  // Surface color and varient dark theme
  --surface-color: #001F2B,
  --on-surface-color: #BFE8FF,
  --surface-variant-color: #3F4948,
  --on-surface-variant-color: #cfe5ff,
  // Terciary color usage dark theme
  --tertiary-color: #76D1FF,
  --on-tertiary-color: #003548,

  //Font opacity
  --light-opacity: rgba($light-primary-text, 0.54),
  --disabled: rgba($light-primary-text, 0.38),
);
