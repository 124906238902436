﻿//Mixins

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

:root {
  @include spread-map($theme-map-light);
}
body.theme-alternate {
  @include spread-map($theme-map-dark);
}


//Text varients
.text-light {
  color: var(--light-opacity);
}

.text-disabled {
  color: var(--disabled);
}

.text-align-c {
  text-align: center;
}

.text-align-r {
  text-align: right;
}

.mat-drawer-container {
  background-color: var(--app-body-color);
}

// Surface //

body, .body-surface {
  background-color: var(--app-body-color);
}

.surface{
  background-color: var(--surface-color);
  color: var(--on-surface-color)
}

.surface-variant{
  background-color: var(--surface-variant-color);
  color: var(--on-surface-variant-color)
}

//Surface light varients
.surface-1{
  background-color: var(--surface-color-1);
  color: var(--on-surface-color)
}
.surface-2{
  background-color: var(--surface-color-2);
  color: var(--on-surface-color)
}
.surface-3{
  background-color: var(--surface-color-3);
  color: var(--on-surface-color)
}
.surface-4{
  background-color: var(--surface-color-4);
  color: var(--on-surface-color)
}
.surface-5{
  background-color: var(--surface-color-5);
  color: var(--on-surface-color)
}


// Containers //

.container-r-4{
  border-radius: 4px;
}
.container-r-8{
  border-radius: 8px;
}

//Primary
.primary-container{
  background-color: var(--primary-container-color);
  color: var(--on-primary-container-color)
}

.primary-container-dark{
  background-color: var(--primary-color);
  color: var(--on-tertiary-color)
}

.primary-container-blue{
  background-color: var(--primary-container-color);
}

.text-primary-color{
  color: var(--primary-color);
}

//Secondary
.secondary-container{
  background-color: var(--secondary-container-color);
  color: var(--on-secondary-container-color)
}

//Tertiary

.tertiary{
  background-color: var(--tertiary-color);
  color: var(--on-tertiary-color)
}
.tertiary-container{
  background-color: var(--tertiary-container-color);
  color: var(--on-tertiary-container-color)
}

.inverse-container{
  background-color: var(--inverse-on-surface);
  color: var(--on-surface-variant-color)
}

//Error

.error-container{
  background-color: var(--error-container-color);
  color: var(--on-error-container-color);
  border-radius: 4px;
}

.error{
  color: var(--error-color);
}

//Checked - Success
.checked{
  color: var(--primary-color);
}

.mat {
  &-display-1{
    font-family: $font-family;
    font-weight: $light;
    font-size: 56px;
  }
  &-display-2{
    font-family: $font-family;
    font-weight: $light;
    font-size: 45px;
  }
  &-display-3{
    font-family: $font-family;
    font-weight: $light;
    font-size: 32px;
  }
  &-display-4{
    font-family: $font-family;
    font-weight: $light;
    font-size: 28px;
  }
  &-headline{
    font-family: $font-family;
    font-weight: $light;
    font-size: 24px;
  }
  &-headline-small{
    font-family: $font-family;
    font-weight: $light;
    font-size: 22px;
  }
  &-title{
    font-family: $font-family;
    font-weight: $semibold;
    font-size: 20px;
  }
  &-subheading-2{
    font-family: $font-family;
    font-weight: $normal;
    font-size: 14px;
  }
  &-subheading-1{
    font-family: $font-family;
    font-weight: $light;
    font-size: 16px;
  }
  &-body-1{
    font-family: $font-family;
    font-weight: $normal;
    font-size: 16px;
  }
  &-body-2{
    font-family: $font-family;
    font-weight: $light;
    font-size: 14px;
  }
  &-caption{
    font-family: $font-family;
    font-weight: $normal;
    font-size: 11px;
  }
  &-bold{
    font-weight: $bold;
  }
  &-semibold{
    font-weight: $semibold;
  }
  &-normal{
    font-weight: $normal;
  }
  &-light{
    font-weight: $light;
  }
}
