﻿// Responsive media for landing page styles

//Angular flex break points
// lg = screen and (min-width: 1280px) and (max-width: 1919.99px)
// lt-xl = screen and (max-width: 1919.99px)
// gt-md = screen and (min-width: 1280px)
// gt-sm = screen and (min-width: 960px)
// gt-xs = screen and (min-width: 600px)


// lg = screen and (min-width: 1280px) and (max-width: 1919.99px)
@media screen and (min-width:1280px) and (max-width:1919.99px) {
}

// lt-xl = screen and (max-width: 1919.99px)
@media screen and (min-width: 1919.99px) {
}

// gt-md = screen and (min-width: 1280px)
@media screen and (min-width: 1280px) {
}

// gt-sm = screen and (min-width: 960px)
@media screen and (min-width: 960px) {
  .mat-mdc-menu-panel.mat-mdc-menu-panel{
    max-width: 100% !important;
  }
}

// gt-xs = screen and (min-width: 600px)
@media screen and (max-width: 600px) {
  .hide-lt-sm{
    display: none !important;
  }
}

// Custom break points
// between 1024px and 768px
@media screen and (min-width:768px) and (max-width:1024px) {
}


.mat-drawer-content{
  height: auto !important;
  width: 100%;
}
.mat-drawer-container{
  height: calc(100% - 64px);
}

.img-landing {
  width: 100%;
  max-width: 350px;
  height: auto;
}

.landing-container{
  border-radius: 16px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.5)
}



/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--primary-color);
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}
