﻿.table-selectors .mat-mdc-form-field-subscript-wrapper{
  display: none;
}

.table-selectors .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control{
  text-align:right;
}

.content-div{
  width: 100%;
  flex-wrap: wrap;
  .wrap-div{
    width: 50%;
    .spacing{
      padding: 0 2px 0 2px;
    }
  }
}
