﻿
// Navigation styling
.mdc-list-item.mdc-list-item--with-one-line {
  border-radius: 50px;
}

//Listing of hours upload

.upload-container{
  border-radius: 4px;
  border: 1px dashed;
  border-color: var(--primary-color);
}

.upload-list{
  background-color: var(--inverse-on-surface)!important;
  color: var(--on-surface-variant-color)!important;
  box-shadow: none!important;
}
