﻿//  The Layout file controls all the margin, padding, positioning, rotation of container elements.
//  *********************
//  Table of Contents:
//   1. Variables
//   2. Box
//      1.1 Box margin
//      1.2 Box padding
//      1.3 Side padding
//      1.4 Side margin


//  1. Spacing for layout
//  -----------------------------------------------
//  box-p-(1-10)
//  1.1 Spacing Margin
$spacing-margin-0: 0rem;
$spacing-margin-1: 0.3rem;
$spacing-margin-2: 0.625rem;
$spacing-margin-3: 0.75em;
$spacing-margin-4: 0.9375rem;
$spacing-margin-5: 1rem;
$spacing-margin-6: 1.25em;
$spacing-margin-7: 1.5625rem;
$spacing-margin-8: 1.875rem;
$spacing-margin-9: 3.125rem;
$spacing-margin-10: 4rem;

//  1.1 Spacing Padding
$spacing-padding-0: 0rem;
$spacing-padding-1: 0.3rem;
$spacing-padding-2: 0.625rem;
$spacing-padding-3: 0.75em;
$spacing-padding-4: 0.9375rem;
$spacing-padding-5: 1rem;
$spacing-padding-6: 1.25em;
$spacing-padding-7: 1.5625rem;
$spacing-padding-8: 1.875rem;
$spacing-padding-9: 3.125rem;
$spacing-padding-10: 4rem;

//  2 Gutters for Layout
//  -----------------------------------------------
//  2.1 Gutters Margin
//  box-m-(1-10)
$gutter-margin-0: 0rem;
$gutter-margin-1: 0.1rem;
$gutter-margin-2: 0.3rem;
$gutter-margin-3: 0.5rem;
$gutter-margin-4: 0.8rem;
$gutter-margin-5: 1rem;
$gutter-margin-6: 1.3rem;
$gutter-margin-7: 1.5rem;
$gutter-margin-8: 1.8rem;
$gutter-margin-9: 2rem;
$gutter-margin-10: 2.5rem;

//  2.1 Gutters Padding
$gutter-padding-0: 0rem;
$gutter-padding-1: 0.1rem;
$gutter-padding-2: 0.3rem;
$gutter-padding-3: 0.5rem;
$gutter-padding-4: 0.8rem;
$gutter-padding-5: 1rem;
$gutter-padding-6: 1.3rem;
$gutter-padding-7: 1.5rem;
$gutter-padding-8: 1.8rem;
$gutter-padding-9: 2rem;
$gutter-padding-10: 2.5rem;


//  Layout style settings
// --------------------

//  Variables
$gutter-margin-values:
  "0" $gutter-margin-0,
  "1" $gutter-margin-1,
  "2" $gutter-margin-2,
  "3" $gutter-margin-3,
  "4" $gutter-margin-4,
  "5" $gutter-margin-5,
  "6" $gutter-margin-6,
  "7" $gutter-margin-7,
  "8" $gutter-margin-8,
  "9" $gutter-margin-9,
  "10" $gutter-margin-10;

$gutter-padding-values:
  "0" $gutter-padding-0,
  "1" $gutter-padding-1,
  "2" $gutter-padding-2,
  "3" $gutter-padding-3,
  "4" $gutter-padding-4,
  "5" $gutter-padding-5,
  "6" $gutter-padding-6,
  "7" $gutter-padding-7,
  "8" $gutter-padding-8,
  "9" $gutter-padding-9,
  "10" $gutter-padding-10;

$side-values:
  "top" top,
  "right" right,
  "bottom" bottom,
  "left" left;

$w-and-h-values:
  "width" width,
  "height" height;


//  1. Box
//  --------------
.box {
  width: 100%;
  height: auto;
  margin: $gutter-margin-0;
  padding: $gutter-padding-0;
}

//  1.1 Box margin
@each $i in $gutter-margin-values {
  .box-m-#{nth($i, 1)} {
    margin: nth($i, 2);
  }
}

//  1.2 Box padding
@each $i in $gutter-padding-values {
  .box-p-#{nth($i, 1)} {
    padding: nth($i, 2);
  }
}

//  1.3 Side padding
@each $j in $side-values {
  @each $i in $gutter-padding-values {
    .#{nth($j, 2)}-p-#{nth($i, 1)} {
      padding-#{nth($j, 2)}: nth($i, 2) !important;
    }
  }
}

//  1.4 Side margin
@each $j in $side-values {
  @each $i in $gutter-padding-values {
    .#{nth($j, 2)}-m-#{nth($i, 1)} {
      margin-#{nth($j, 2)}: nth($i, 2) !important;
    }
  }
}
