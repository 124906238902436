﻿.avatar{
  border-radius: 100%;
  &-medium{
    min-width: 60px;
    height: 60px;
  }
  &-small{
    min-width: 40px;
    height: 40px;
    font-size: medium;
  }
  &-outline{
    border: solid;
    border-color: var(--on-surface-variant-color);
  }
  &-outline-dashed{
    border:1px dashed;
    border-color: var(--on-surface-variant-color);
  }
}

.total-points {
  .container-left {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .container-right {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .container{
    border-radius: 12px;
  }
}
