﻿mat-nav-list{

  div{
    margin-top: 5px;
    margin-bottom: 5px;
    a{
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      padding: 0 5px;
      position: relative;
      height: inherit;
      mat-icon{
        color: white;
        align-self: center;
      }
      span{
        color: white;
        align-self: start;
      }
    }
  }
}
