@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 72.2% 50.6%;
    --primary-foreground: 0 85.7% 97.3%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 72.2% 50.6%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 72.2% 50.6%;
    --primary-foreground: 0 85.7% 97.3%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 72.2% 50.6%;
  }
}

@layer base {
  * {
    @apply border-border text-[14px]
  }
  body {
    @apply bg-background text-foreground;
  }
}


@layer utilities {
  .sideNavOpen {
    @apply sl:left-0 md:left-0  xmd:left-0 md:w-[225px] xmd:w-[225px] sl:w-[225px] lg:left-0 x2l:left-0 lg:w-[225px] x2l:w-[225px] overflow-hidden;
  }
  .sideNavClose {
    @apply sl:-left-[225px] md:-left-0 xmd:-left-0 sl:w-[225px] md:w-[60px] xmd:w-[60px] lg:left-0 x2l:left-0 lg:w-[60px] x2l:w-[60px] overflow-hidden;
  }
  .mainContentOpen {
    @apply sl:pl-0 md:pl-[65px] xmd:pl-[65px]  lg:pl-[65px] x2l:pl-[65px] pr-2
  }
  .mainContentClose {
    @apply sl:pl-0 md:pl-[230px] xmd:pl-[230px] lg:pl-[230px] x2l:pl-[230px] pr-2
  }
  .rotateLogo {
    @apply rotate-[360deg]
  }
  .rotateSubMenuIconOpen {
    @apply rotate-[180deg]
  }
  .rotateSubMenuIconClose {
    @apply rotate-[0deg]
  }
  .hideNavText {
    @apply py-[10px] pr-[30px] pl-[20px] my-[5px]
  }
  .showNavText {
    @apply py-[10px] pr-[30px] pl-[14px] my-[5px] mr-0
  }
  .moveNavTextRight {
    @apply mr-[10px]
  }
  .moveNavTextBack {
   @apply mr-0
  }
  .sideBarLarge {
    @apply flex flex-col h-screen pt-3 sideNavBackGround duration-500 delay-300 absolute z-50 hover:w-[225px] hover:delay-0
  }
  .sideBarMobile {
    @apply absolute h-screen pt-3 sideNavBackGround duration-500 z-50
  }
  .removeText {
    @apply  hover:scale-100 scale-0
  }
  .sideNavBackGround {
    @apply bg-gradient-to-b from-nav-blue to-nav-red ;
  }
  .uiBefore{
    @apply before:content-[""] before:block before:z-[-200] before:absolute before:-left-[100%] before:top-[0] before:w-[100%] before:h-[100%] before:border-r-[5px] before:border-[#FF586B] before:border-solid before:bg-nav before:duration-500 before:transition-all
  }
  .active {
    @apply before:left-[0]
  }
  .bg_success{
    @apply bg-[#30CB91]
  }
  .bg_warning{
    @apply bg-[#FF9E78]
  }
  .bg_danger{
    @apply bg-[#FF7181]
  }
  .bg_grey{
    @apply bg-[#bdbdbd]
  }
  .text_success{
    @apply text-[#0CC27E]
  }
  .text_warning{
    @apply text-[#FF8D60]
  }
  .text_danger{
    @apply text-[#FF586B]
  }
  .text_muted{
    @apply text-[#6c757d]
  }
  .redTextColor{
    @apply text-red-700;
  }
  .greenTextColor{
    @apply text-green-600;
  }
  .blackTextColor{
    @apply text-black;
  }

  .primaryRedTextColor{
    @apply text-nav-red;
  }

  //Request Details Classes
  .requestDetailsMainContentOpen {
    @apply bg-background border border-border rounded-lg p-4
  }
  .requestDetailsMainContentClose {
    @apply bg-background border border-border rounded-lg p-4
  }
}

.no-scrollbar::-webkit-scrollbar{
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzAwNjI5ZSIsIj9lcjwjY2ZlNWZmIiwiO2VyPCMwMDFkMzR$LCIlPmBePCMwMDY1OGQiLCI~ZXI8I2M2ZTdmZiIsIjtlcjwjMDAxZTJkfiwid2Fybj5gXjwjYTUwMDEyIiwiP2VyPCNlNGIzYjgiLCI7ZXI8IzhhMDAwYX4sIj9UZXh0PCMxOTFjMWQiLCI~PTwjZmJmY2ZlIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb225cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/

@import '@angular/material/_theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.mdc-notched-outline__notch
{
  border-right: none;
}

html,
body {
  height: 100vh !important;
  margin: 0;
  overflow: auto;
}


* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent; }
*:focus {
  outline: 0; }


//Import all other project files here
@import "./assets/styles/theme.scss";
@import "./assets/styles/mixin.scss";
@import "./assets/styles/global.scss";
@import "./assets/styles/layout.scss";

//Import all component override files here

@import "./assets/components/avatar.scss";
@import "./assets/components/mat-table.scss";
@import "./assets/components/side-nav.scss";
@import "./assets/components/chips.scss";
@import "./assets/components/mat-list.scss";
@import "./assets/components/mat-input.scss";
@import "./assets/components/mat-card.scss";

