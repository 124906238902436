﻿.mat-mdc-table{
  background: var(--app-body-color);
}

//Sticky table on scroll

.mat-mdc-table th  {
  position: sticky;
  top: -30px; /* Don't forget this, required for the stickiness */
  //   box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  z-index: 20;
}

.mat-mdc-paginator{
  background: var(--app-body-color);
}

.mdc-menu-surface{
  background: var(--surface-color-1);
  font-weight: $light;
}

.mat-mdc-menu-item:hover:not([disabled]), .mat-mdc-menu-item.cdk-program-focused:not([disabled]), .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]), .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: var(--surface-variant-color);
}

.results-box{
  border-radius: 4px;
}

.mat-column-name{
  width:45%;
  max-width:45%;
  word-wrap: break-word;
  .error{
    width: 24px;
    display: table;
  }
}


.mat-sort-header-content {
  text-align: left !important;
}

table.custom-table .mdc-data-table__cell {
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  background: var(--inverse-on-surface);

  .round-c-left{
    border-radius: 8px 0px 0px 8px;
  }
  .round-c-right{
    border-radius: 0px 8px 8px 0px;
  }
}

table.custom-table .mdc-data-table__cell, .mdc-data-table__header-cell{
  border-bottom-width: 0;
  border-bottom-style: none;

}

table.custom-table.mdc-data-table__table {
  border-spacing: 0 8px;
}

table.custom-table .mdc-data-table__cell.round-c-left {
  border-radius: 4px 0px 0px 4px;
}

table.custom-table .mdc-data-table__cell.round-c-right {
  border-radius: 0px 4px 4px 0px;
}
